@import './../../styles/variables.scss';

.modale-container {
  @include transition;
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.isActive {
    background-color: rgba($primary , 0.7);
    z-index: 1001;
  }
  .modale {
    @include transition;
    pointer-events: all;
    background-color: $white;
    transform: scale(0);
    border-radius: $radius;
    &.isActive {
      transform: scale(1);
    }
    .close {
      float: right;
      width: 32px;
      height: 32px;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      img {
        width: 25px;
        height: 25px;;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  } 
}