
@import '../../../../../styles/variables.scss';

.product-modale {
  width: 56%;
  max-height: 80vh;
  overflow-y: auto;
  .ql-toolbar {
    margin-top: 5px;
    &.ql-snow {
      border: 1px solid $primary;
    }
  }
  .ql-container {
    min-height: 250px;
    font-family: "GlacialIndifference-Regular";
    &.ql-snow {
      border: 1px solid $primary;
    }
  }
}

.product-row {
  @include transition;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: $dark;
  &.border {
    padding-right: 0px;
    border: 1px solid $primary;
    margin-bottom: 10px;
    &:hover {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }
  p {
    text-align: center;
    margin: 11px 0px;
    font-size: 14px;
  }
  .label {
    width: calc(100% / 5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon {
  @include btn;
  width: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  &:hover {
      background-color: $primary;
  }
  img {
      width: 5px;
  }
}
