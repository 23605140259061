
@import '../../../../../styles/variables.scss';

.quote-modale {
  width: 56%;
  max-height: 80vh;
  overflow-y: auto;
  .form-container {
    margin: 30px;
    input {
      margin-bottom: 20px;
    }
    .btn-container {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
}

.quote-row {
  @include transition;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: $dark;
  position: relative;
  &.label-row {
    width: calc(100% - 22px);
  }
  &.border {
    padding-right: 0px;
    border: 1px solid $primary;
    margin-bottom: 10px;
    &:hover {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }
  p {
    text-align: center;
    margin: 5px 0px;
    font-size: 14px;
  }
  .quote-label {
    width: calc(100% / 6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.quote-container {
  display: flex;
  justify-content: space-between;
  .add-product {
    display: flex;
    .select-product {
      min-width: 300px;
    }
    .btn {
      margin-top: 7px;
      margin-left: 20px;
    }
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      text-align: center;
    }
    .bar {
      border-bottom: 1px solid $dark;
      width: 38%;
      transform: translateY(-25px)
    }
  }
  .quote-content {
    width: 100%;
    h2 {
      margin-top: 20px;
      margin-bottom: 0px;
      font-size: 14px;
    }
    .quote-client {
      width: 100%;
      display: flex;
      justify-content: space-between;
      textarea {
        min-height: 150px
      } 
      .col-2 {
        width: 40%;
        .address {
          margin: 0px;
          text-transform: uppercase;
          font-size: 14px;
        }

        .ql-toolbar {
          margin-top: 5px;
          &.ql-snow {
            border: 1px solid $primary;
          }
        }
        .ql-container {
          min-height: 150px;
          font-family: "GlacialIndifference-Regular";
          &.ql-snow {
            border: 1px solid $primary;
            border-top: 0px solid $primary;
          }
        }
      }
    }
  }
  .quote-actions {
    flex-direction: row;
    align-items: flex-end;
    .btn {
      margin-left: 20px;
      margin-bottom: 10px;
      min-width: 120px;
    }
  }
}

.edit-product-row {
  display: flex;
  .name {
    width: 35%;
    display: flex;
    justify-content: space-between;
    .btn-container {
      display: flex;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        img {
          @include transition;
          &:hover {
            transform: scale(1.2)
          }
        }
      }
    }
  }
  .value {
    width: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      text-align: center
    }
    &.total {
      justify-content: flex-end;
    }
    input {
      text-align: center;
      max-width: 100px;
      margin: 0px;
      max-height: 28px;
    }
  }
}

.total-row {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-top: 1px solid $dark;
  margin-top: 10px;
  padding: 10px 0px;
  p {
    margin: 5px 0px;
  }
}
