@import '../../../../../styles/variables.scss';

.dashboard {
  min-height: 100vh;
  display: flex;
  align-items: center;
  .col-content {
    width: calc(65% - 10px);
  }
  .col-aside {
    width: calc(35% - 10px);
  }

  .box {
    @include opacity-box;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    &.top-five {
      min-height: 288px;
      h2 {
        margin: 0px 0px 20px 0px;
      }
      .top-row {
        display: flex;
        justify-content: space-between;
        p {
          word-break: break-word;
          text-transform: uppercase;
          max-width: 60%;
          margin: 10px 0px
        }
      }
    }
    .select {
      max-width: 300px;;
    }
    .bar-chart-container {
      height: 386px;
      width:100%;
    }
    .periode-picker {
      background-color: transparent;
	    box-shadow: none;
	    border-radius: $radius;
      padding: 0px;
    }
    .receipts-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .receipt-value {
        width: 33.33%;
        display: flex;
        align-items: center;
        &.bar {
          border-right: 1px solid $dark;
          width: calc(33.33% - 20px);
        }
        p {
          margin: 0px;
        }
        .icon {
          cursor: auto;
          width: 38px;
          height: 38px;
          margin: 0px 10px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.global {
            background-color: #C9BBB1;
          }
          &.paid {
            @include transition;
            background-color: #6E9B13;
            cursor: pointer;
            span {
              font-size: 13px;
              color: #BADC62
            }
            // &:hover {
            //   transform: scale(1.1);
            // }
          }
          &.late {
            background-color: #FF5A51;
          }
        }
      }
    }
  }
}