@import './../../styles/variables.scss'; 

.toaster {
  @include transition;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $primary;
  border-radius: $radius;
  width: 180px;
  margin: 20px;
  padding: 10px;
  transform: translateX(-240px);
  p {
    margin: 0px;
    min-height: 38px;
    text-align: left;
  }
  &.success {
    background-color: $success;
    color: $white;
  }
  &.error {
    background-color: $error;
    color: $white;
  }
  &.isActive {
    transform: translateX(0px);
  }
}