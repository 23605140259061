@import '../../../../styles/variables.scss';

.user-row {
    @include transition;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    color: $dark;
    &.border {
      border: 1px solid $primary;
      margin-bottom: 10px;
      &:hover {
        background-color: $white;
        box-shadow: $box-shadow;
      }
    }
    p {
      text-align: center;
      letter-spacing: 1px;
      word-break: break-all;
      margin: 11px 0px;
      font-size: 14px;
    }
    .label {
        width: calc(100% / 3 );
    }
    &.client {
      .label {
        width: calc(100% / 5 );
      }
    }
}

.icon {
    @include btn;
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    &:hover {
      background-color: $primary;
    }
    img {
        width: 5px;
    }
}

.user-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.bank {
    margin-top: 30px;
    min-height: 0vh;
    justify-content: flex-end;
  }
  &.loader {
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    h1 {
      font-family: "Madelyn-Regular";
      text-transform: capitalize;
      font-size: 58px;
      transform: translate(-5px , 5px);
    }
    .user-icon {
      background-color: $primary;
      width: 82px;
      height: 82px;
      margin-right: 40px;
      border-radius: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      text-transform: uppercase;
    }
    .buttons {
      position: absolute;
      right: 40px;
    }
  }
  .user-content {
    margin-left: 120px;
    width: calc(100% - 120px);
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
    }
    .buttons {
      position: absolute;
      right: 40px;
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
    .user-infos {
      display: flex;
      flex-direction: column;
      width: 48%;
      .mandat {
        background-color: $success;
        max-width: 200px;
        padding: 10px;
        color:$white;
        border-radius: $radius;
      }
      .label {
        text-transform: uppercase;
      }
      .createdAt {
        font-size: 14px;
      }
      .dates-ca {
        max-width: 300px;
      }
      .ca {
        color: $primary;
        font-size: 18px;
      }
      h3 {
        margin: 0px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.list {
  margin-top: 30px;
  h2 {
    margin-top: 0px;
  }
}

.invoice-row {
  @include transition;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: $dark;
  position: relative;
  &.border {
    padding-right: 0px;
    border: 1px solid $primary;
    margin-bottom: 10px;
    &:hover {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }
  p {
    text-align: center;
    margin: 5px 0px;
    font-size: 14px;
  }
  .invoice-label {
    width: calc(100% / 4);
  }
  .refused {
    color: $error;
  }
}