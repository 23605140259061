@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  height: 100%;
  bottom: 0;
  box-sizing: border-box;
  font-family: "GlacialIndifference-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

p {
  font-size: $regular;
}

p, h1, h2 {
  letter-spacing: 1px;  
} 

label {
  font-size: $regular;
}

button {
  @include btn;
}

.row-state {
  &-error {
    color: $error
  }
  &-success {
    color: $success
  }
}

h1 {
  font-family: "GlacialIndifference-Bold";
  text-transform: uppercase;
  margin: 0px;
  font-size: 24px;
}

h2 {
  font-family: "GlacialIndifference-Bold";
  text-transform: uppercase;
  font-size: $h2
}

.d-none {
  display: none;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-family: "GlacialIndifference-Bold";
}

input , textarea {
  width: 100%;
  border: 1px solid $primary;
  padding: 8px 5px;
  font-size: 14px;
  margin-top: 4px;
  font-family: "GlacialIndifference-Regular";
  background-color: $white;
  transition: all 0.2s ease-in-out;
  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.page-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('../assets/images/bg.png');
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.mobile-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 200px;
  }
  p {
    text-align: center;
  }
}

.nav-container {
  position: absolute;
  width: 20%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .logo {
    width: calc(100% -60px);
    height: auto;
    margin: 30px;
    img {
      max-width: 100%;
    }
  }
  ul {
    padding: 0px;
  }
  li {
    list-style: none;
    margin: 30px 0px;
    a {
      color: $primary;
      text-decoration: none;
      @include transition;
      &:hover {
        color: $primary-dark;
      }
      &.isActive {
        color: $dark;
      }
    }
  }
}

.page-content {
  margin-left: 20%;
  width: 80%;
  padding: $margin-md $margin-md  200px $margin-md;
}

.router-transition-container {
  overflow: auto;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.row {
  width: 100%;
  .col-1 {
    @include opacity-box;
    width: 100%;
    padding: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      h2 {
        margin: 0px;
      }
      .actions {
        display: flex;
        align-items: flex-start;
        input {
          width: 250px;
          padding-left: 10px;
          font-size: 16px;
          margin: 0px 20px;
          min-height: 39px;
        }
      }
    }
  }
}

.row-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .col-2 {
    width: calc(50% - 10px);
  }
}

.text-error {
  font-size: $small;
  margin: 0px;
  min-height: 16px;
  color: $error;
}

.text-center {
  text-align: center;
}

.list-container {
  margin-top: 20px;
  min-height: 64vh;
}

.btn {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  &.success {
    background-color: $success;
    &:hover {
      background-color: $success;
    }
  }
  &.grey {
    background-color: $light-grey;
    &:hover {
      background-color: $grey;
    }
  }
  &.disabled {
    background-color: $light-grey;
    pointer-events: none;
    cursor: not-allowed;
    &:hover {
      background-color: $grey;
    }
  }
  &.primary {
    background-color: $primary;
    &:hover {
      background-color: $primary-dark;
    }
    &.send {
      min-width: 320px;
    }
  }
  &:focus {
    outline: none;
  }
}

.custom-email {
  max-width: 100%;
  min-height: 160px;
}

.filters {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  @include opacity-box;
  .col-4 {
    width: calc(33.33% - 10px);
    .header {
      display: flex;
      align-items: center;
      transform: translateY(-10px);
      h2 {
        margin-right: 20px;
      }
      .primary {
        padding: 5px 10px;
      }
    }
    &.search {
      display: flex;
      align-items: center;
      button {
        background-color: $primary;
        margin: 1px 0px 0px 10px;
        min-width: 37px;
        height: 37px;
        padding: 8px;
        img {
          opacity: 0.5;
        }
      }
    }
    input {
      transform: translateY(-1px);
      height: 38px;
    } 
    &.end {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}

.periode-picker {
  h2 {
    margin-top: 0px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 10px;
    }
  }
}

.draft {
  background-color: transparent;
  color: $dark;
}

@mixin state {
  margin: 5px 0px;
  color: $white;
  width: 100px;
  padding: 6px 0px;
  text-transform: uppercase;
  font-family: "GlacialIndifference-Bold";
}

.draft {
  @include state;
  background-color: transparent;
  color: $dark;
}

.seen {
  @include state;
  background-color: #FFDE59;
}

.sent {
  @include state;
  background-color: #22dae1;
}

.late {
  @include state;
  background-color: #F88741;
}

.paid {
  @include state;
  background-color: #C9E265;
}

.valid {
  @include state;
  background-color: #FF56C4;
}

.refused {
  @include state;
  background-color: #F88741;
}


.react-datepicker__input-container {
  max-width: 160px;
  input {
    text-align: center;
  }
}

.react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #ffffff;
}

.react-datepicker {
  font-family: "GlacialIndifference-Regular";
  border-radius: 0px;
  border: 1px solid $primary;
}

.react-datepicker__header {
    text-align: center;
    background-color: $white;
    border-bottom: 0px; 
}

.react-datepicker__today-button {
  background: $white;
  border-top: 0px;
}
.react-datepicker__day {
  background-color: transparent;
  color: $dark;
  border-radius: 20px;
  outline: none;
  &:hover {
    background-color: $primary;
    border-radius: 20px;
  }
  // &--selected {
  //   border-radius: 0.3rem;
  //   background-color: $primary;
  //   color: $dark;
  // }
  // &--in-range {
  //   border-radius: 0.3rem;
  //   background-color: $primary;
  //   color: $dark;
  // }
  // &--in-selecting-range {
  //   border-radius: 0.3rem;
  //   background-color: $primary;
  //   color: $dark;
  // }
  // &--in-selecting-range:not(&--in-range) {
  //   background-color: $primary;
  // }
}

.dropbtn {
  @include btn;
  display: flex;
  height: calc(100% - 10px);;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.2)
  }
  img {
    min-width: 5px;
    margin: 0px 2px;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: $white;
  min-width: 160px;
  transform: translate(-160px , -42px );
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

@mixin dropDown-btn {
  width: 100%;
  color: $primary-dark;
  padding: 8px;
  text-align: right;
  font-size: 14px;
  text-decoration: none;
  display: block;
  &:hover {
    color: $dark;
  }
}
/* Links inside the dropdown */
.dropdown-content button {
 @include dropDown-btn;
}

.dropdown-content a {
  text-decoration: none;
  @include dropDown-btn;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content { 
  display: block;
}

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .label {
    margin-top: 3px;
  }
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $primary-dark;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ql-toolbar {
  margin-top: 5px;
  &.ql-snow {
    border: 1px solid $primary;
  }
}
.ql-container {
  min-height: 250px;
  background-color: $white;
  font-family: "GlacialIndifference-Regular";
  &.ql-snow {
    border: 1px solid $primary;
  }
}

.date-row {
  display: flex;
  justify-content: space-between;
  .date {
    width: calc(50% - 10px);
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        max-width: 100%;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0px;
  margin: 0px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 20px;
    height: 20px;
    margin: 0px 5px;
    border-radius: 10px;
    &:hover {
      background-color: rgba($primary, 0.4);
    }
    &.active {
      background-color: $primary;
    }
  }
}
