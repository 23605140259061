$box-opacity : rgba(255, 255, 255 , 0.70);
$box-shadow : 3px 3px 10px 0px rgba(0, 0, 0, 0.05);

$white: #FFFFFF;
$dark: #000000;
$light-grey: #d6d6d6;
$grey: #9c9c9c;


$primary: #C9BBB1;
$primary-dark: rgb(185, 172, 162);
$primary-light: rgb(243, 227, 215);

$success: #49aa56;
$error : red;


$lg-screen:1200px;
$md-screen: 1025px;
$sm-screen: 768px;
$xs-screen: 576px;

$radius : 4px;

$small : 13px;
$regular : 14px;
$h2 : 18px;
$h1 : 25px;

$margin-md : 30px;
$margin-sm : 15px;
$margin-xs : 10px;



// @font-face {
// 	font-family: "Montserrat-Regular";
// 	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
// }

// @font-face {
// 	font-family: "Montserrat-SemiBold";
// 	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
// }

@font-face {
	font-family: "Madelyn-Regular";
	src: url("../assets/fonts/madelyn-webfont.woff") format("woff");
}

@font-face {
	font-family: "GlacialIndifference-Regular";
	src: url("../assets/fonts/GlacialIndifference-Regular.otf") format("truetype");
}


@font-face {
	font-family: "GlacialIndifference-Bold";
	src: url("../assets/fonts/GlacialIndifference-Bold.otf") format("truetype");
}


@mixin btn {
  text-align: center;
	cursor: pointer;
	font-family: "GlacialIndifference-Regular";
	transition: all 0.2s ease-in-out;
	background-color: transparent;
  outline: none;
  border: none;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin opacity-box {
	background-color: $box-opacity;
	box-shadow: $box-shadow;
	border-radius: $radius;
}