
@import '../../../../../styles/variables.scss';

.credit-modale {
  width: 56%;
  height: 640px;
  overflow-y: auto;
  .ql-toolbar {
    margin-top: 5px;
    &.ql-snow {
      border: 1px solid $primary;
    }
  }
  .ql-container {
    min-height: 250px;
    font-family: "GlacialIndifference-Regular";
    &.ql-snow {
      border: 1px solid $primary;
    }
  }
}

.credit-row {
  @include transition;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: $dark;
  position: relative;
  &.border {
    padding-right: 0px;
    border: 1px solid $primary;
    margin-bottom: 10px;
    &:hover {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }
  p {
    text-align: center;
    margin: 11px 0px;
    font-size: 14px;
  }
  &.label-row{
    pointer-events: none;
    .icon {
      opacity: 0;
    }
  }
  .credit-label {
    width: calc(100% / 6 );
  }
}

.credit-container {
  display: flex;
  justify-content: space-between;
  .add-product {
    display: flex;
    width: 100%;
    .select-product {
      width: 30%;
    }
    .btn {
      margin-top: 7px;
      margin-left: 20px;
    }
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .bar {
      border-bottom: 1px solid $dark;
      width: 38%;
      transform: translateY(-25px)
    }
  }

  .credit-content {
    width: 85%;
    .credit-client {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .col-2 {
        width: 48%;
        .ql-toolbar {
          margin-top: 5px;
          &.ql-snow {
            border: 1px solid $primary;
          }
        }
        .ql-container {
          min-height: 150px;
          font-family: "GlacialIndifference-Regular";
          &.ql-snow {
            border: 1px solid $primary;
            border-top: 0px solid $primary;
          }
        }
      }
    }
  }
  .credit-actions {
    width: 12%;
    flex-direction: column;
    align-items: flex-end;
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.edit-product-row {
  display: flex;
  .name {
    width: 35%;
    display: flex;
    justify-content: space-between;
    .btn-container {
      display: flex;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        min-width: 36px;
        img {
          @include transition;
          &:hover {
            transform: scale(1.2)
          }
        }
      }
    }
  }
  .value {
    width: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.total {
      justify-content: flex-end;
    }
    input {
      text-align: center;
      max-width: 100px;
      margin: 0px;
      max-height: 28px;
    }
  }
}

.provider-row {
  display: flex;
  .select-provider {
    width: 30%;
    margin-right: 5%;
    display: flex;
    img {
      @include transition;
      &:hover {
        transform: scale(1.2)
      }
    }
  }
  .value {
    width: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.total {
      justify-content: flex-end;
    }
    input {
      text-align: center;
      max-width: 100px;
      margin: 0px;
      max-height: 28px;
    }
  }
}

.total-row {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-top: 1px solid $dark;
  margin-top: 10px;
  padding: 10px 0px;
  p {
    margin: 5px 0px;
  }
  .tva {
    width: 200px;
    display: flex;
    justify-content: space-between;
  }
}

.valid-credit-modale {
  button {
    &.close {
      display: none;
    }
  }
  p {
    text-align: center;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    height: 300px;
    margin: 30px;
    button {
      margin:10px;
      min-width: 240px
    }
  }
}
