@import '../../../../styles/variables.scss';

.user-modale {
  width: 30%;
  height: 650px;
  max-height: 650px;
  overflow-y: auto;
}

.account-modale {
  width: 30%;
  height: 280px;
  overflow-y: auto;
}

.edit-user-container {
  margin: 20px;
  form {
    height: 560px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.account {
      height: 200px;
    }
    .btn {
      margin: 10px;
    }
    .form-container {
      width: 90%;
      textarea {
        max-width: 100%;
        min-height: 150px
      }
      &.end {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }
}

.invoices-modale {
  width: 75%;
  height: auto;
  overflow-y: auto;
  .invoices-list {
    margin: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .invoice {
      width: 100%;
      border: 1px solid $primary;
      margin: 10px 10px; 
      .invoice-row {
        @include transition;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;


        p {
          min-width: 138px;
          margin: 5px;
          font-size: 14px;
        }
        button {
          min-width: 138px;
          margin: 5px 5px 5px 0px;
        }
      }
    }
  }
}