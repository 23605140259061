@import '../../../styles/variables.scss';

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  .login-container {
    @include opacity-box;
    width: 400px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .logo {
      width: 60%;
      height: auto;
    }
    .form-container {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      img {
        float: right;
        width: 22px;
        margin-top: -25px;
        margin-bottom: 10px;
        transform: translateX(310px);
      }
      .btn-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .error-connexion {
          transform: translateY(-20px);
        }  
        .btn-reset {
          margin-top: 10px;
        }
      }
    }
  }
}


