@import '../../../../styles/variables.scss';

.attestation-row {
  @include transition;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: $dark;
  position: relative;
  padding: 0px 8px;
  border: 1px solid $primary;
  margin-bottom: 10px;
  cursor: pointer;
  p {
    padding-left: 20px;
    text-align: center;
    text-transform: uppercase;
  }
}